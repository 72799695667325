.blog {
  .hero {
    max-width: 1192px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 148px;
    .title {
      font-size: 48px;
      font-weight: 700;
      color: #141414;
      margin-bottom: 12px;

      span {
        font-weight: 400;
        color: #0d924f;
      }
    }

    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 32px;
    }

    .group {
      display: flex;
      align-items: center;
      max-width: 414px;
      width: 100%;
      height: 55px;
      border-radius: 12px;
      border: 1px solid #898989;
      display: grid;
      grid-template-columns: 1fr 172px;

      input {
        // width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        font-size: 14px;
        font-weight: 400;
        color: #898989;
        padding: 0 18px;
      }
      button {
        // width: 135px;
        height: 100%;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border-radius: 12px;
        background-color: #063;
        border: none;
        outline: none;
        margin-left: 20px;
        padding: 0 18px;
      }
    }
  }

  &__search {
    max-width: 1192px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 49px;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: start;
      gap: 20px;
    }

    &__inner {
      display: flex;
      gap: 8px;

      & > select {
        border: none;
        border-radius: 8px;
        background: #fafafa;
        width: 70px;
        height: 55px;
        padding: 10px;
        gap: 10px;
        color: var(--Primary-color, #063);
      }

      & > div {
        display: flex;
        width: 749px;
        height: 55px;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #fafafa;

        @media (max-width: 1024px) {
          width: 100%;
        }

        & > input {
          width: 100%;
          height: 100%;
          border: none;
          background: none;
          &::placeholder {
            color: var(--Grey-2, #b1b1b1);
          }
        }

        & > button {
          width: 135px;
          height: 43px;
          padding: 4px 12px;
          border-radius: 10000px;
          background: var(--Primary-color, #063);
          border: none;
          color: var(--White, #fff);
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      color: #141414;

      & > select {
        font-size: 16px;
        font-weight: 500;
        color: #0d924f;
        border: none;
      }
    }
  }

  &__title {
    max-width: 1192px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    color: var(--Green-1, #0d924f);
    font-size: 36px;
    font-weight: 700;
  }

  .blogPosts {
    // background-color: yellow;

   
    .gridMain {
      max-width: 1192px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      display: grid;
      gap: 32px 24px;
      margin-top: 24px;
      grid-template-columns: 1fr 1fr;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
      }

      & > div {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          // height: auto;
          height: 445px;
          object-fit: cover;
          border-radius: 12px;
          margin-bottom: 16px;
        }

        .subtitle_group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .subtitle {
            font-size: 14px;
            color: #898989;
            span {
              color: #0d924f;
            }
          }
        }

        .second_title {
          font-size: 24px;
          font-weight: 700;
          color: #141414;
          margin-bottom: 8px;
        }

        .second_subtitle {
          font-size: 16px;
          font-weight: 400;
          color: #3b3b3b;
          margin-bottom: 4px;
          line-height: 137.5%;

        }

        .time {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 23px;
          position: absolute;
          top: 24px;
          left: 24px;
          padding: 4px;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.5);
        }

        .button_group {
          display: flex;
          align-items: center;

          & > div {
            width: fit-content;
            padding: 10.02px;
            color: #063;
            font-size: 12px;
            font-weight: 400;
            border-radius: 12.026px;
            border: 1.002px solid var(--Grey, #898989);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & > :nth-child(even) {
            margin: 0 16.03px;
          }
        }
      }
    }

    .grid1 {
      grid-template-columns: 1.42fr 1fr;
      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
      }
    }

    .stories {
      max-width: 1192px;
      width: 100%;
      margin: 0 auto;
      padding: 0 24px;
      margin-top: 48px;
      .title {
        font-size: 36px;
        color: #0d924f;
        margin-bottom: 24px;
        font-weight: 700;
      }
      .storiesGrid {
        overflow: auto;
        display: flex;
        align-items: start;
        max-width: 100%;
        padding: 0;

        // justify-content: center;

        &::-webkit-scrollbar {
          display: none;
        }

        & > div {
          width: fit-content;
          & > div {
            width: 365px;

            & > img {
              height: 368px;
            }
          }
        }
      }
    }
  }

  .read {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--Green-1, #0D924F);
font-size: 16px;
font-weight: 500;
  }
}


